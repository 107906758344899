

















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { isMainSection, SectionLink } from "@/vue/services/entrance/entrance";
import { EntranceSections } from "@/vue/organisms/entrance/common/IronDoorEntranceSectionsEnum";

const phoneBreakpoint = 745;
const handheldsBreakpoint = 960;
const unknown = "UNKNOWN";

interface SectionElement extends SectionLink {
  domElement?: any;
}

@Component
export default class IronDoorStickyHeader extends Vue {
  @Prop({
    required: true
  })
  mounted() {}
}
