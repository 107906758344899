
















import { Component, Mixins, Prop } from "vue-property-decorator";
import IronDoorStickyHeader from "@/vue/organisms/entrance/common/IronDoorStickyHeader.vue";
import WithCmsContent from "@/vue/mixins/WithCmsContent";
import IronDoorUniversalLoginForm from "@/vue/organisms/entrance/login/IronDoorUniversalLoginForm.vue";
import IronDoorHero from "@/vue/organisms/entrance/common/IronDoorHero.vue";
import { EntranceSections } from "@/vue/organisms/entrance/common/IronDoorEntranceSectionsEnum";

@Component({
  components: {
    IronDoorHero,
    IronDoorStickyHeader,
    IronDoorUniversalLoginForm
  }
})
export default class IronDoorEntranceComponent extends Mixins(
  WithCmsContent
) {
  // Login
  @Prop({ required: false, default: "false" })
  @Prop()
  private locale?: string;
  @Prop() private cloudflareCountry?: string;
  @Prop() private authorizeUrl: any;
  @Prop() private gtmLoginEvents: any;
  @Prop() private ga4LoginEvents: any;
  @Prop({ required: false }) private entranceStatusMessage?: string;
  @Prop({ required: false }) private entranceStatusSuccess?: boolean;
  @Prop({ required: false }) private prefilledUsername?: string;
  @Prop({ required: false, default: "" }) private savedRequestUrl!: string;
  @Prop({ required: false, default: false })
  private rememberMeToken!: boolean;
  @Prop() private loginDoubleClickPreventor!: string;
  @Prop() private registrationLinkText?: string;
  @Prop() private processingHint!: string;

  get currentComponentProps() {
    return {
      authorizeUrl: this.authorizeUrl,
      gtmLoginEvents: this.gtmLoginEvents,
      ga4LoginEvents: this.ga4LoginEvents,
      entranceStatusMessage: this.entranceStatusMessage,
      entranceStatusSuccess: this.entranceStatusSuccess,
      savedRequestUrl: this.savedRequestUrl,
      doubleClickPreventor: this.loginDoubleClickPreventor,
      prefilledUsername: this.prefilledUsername,
      rememberMeToken: this.rememberMeToken,
      processingHint: this.processingHint,
      registrationLinkText: this.registrationLinkText
    };
  }

  created() {}

  mounted() {
    this.hideScrollBar();
  }
  private hideScrollBar() {
    document.documentElement.style.overflowY = "scroll";
    let styleSheet = document.createElement("style");
    styleSheet.innerText = `
          html {
              scrollbar-width: none;
          }
          html::-webkit-scrollbar {
              display: none;
          }
      `;
    document.head.appendChild(styleSheet);
  }
}
